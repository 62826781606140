import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import login, {
  firebasePhoneLogin,
  getOTPBySMS,
  loginWithGuest,
  loginWithSmsOTP,
} from '../../services/auth';
import Button from '../button/button';
import { Loading } from '../loading/loading';
import ModalComponent from '../modal/modal';
import LoginTextInput from './loginTextInput';
import OtpDialog from './otpDialog';
import { isValidPhoneNumber } from '../../services/helper';
import {shouldRequestServerOTP} from "../../services/utils";

const LoginDialog = ({ ...props }) => {
  const [countryCode] = useState('+60');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [openOtp, setOpenOtp] = useState(false);

  useEffect(() => {
    window.recaptchaVerifier = new window.firebase.auth.RecaptchaVerifier(
      'sign-in-button',
      {
        size: 'invisible',
        callback: function(response) {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
      },
    );
  }, []);

  function onPhoneNumberChange(event) {
    let value = event.target.value.trim();
    value = value.replace('+', '');
    if (!isNaN(value)) {
      setPhoneNumber(value.startsWith('01') ? `+6${value}` : `+${value}`);
    }
  }

  function requestFirebaseOTP(finalNumber) {
    const appVerifier = window.recaptchaVerifier;

    window.firebase
      .auth()
      .signInWithPhoneNumber(finalNumber, appVerifier)
      .then(confirmationResult => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;

        setOpenOtp(true);
      })
      .catch(function(error) {
        // Error; SMS not sent
        // ...
        if (error.code === 'auth/invalid-phone-number') {
          alert(`Invalid Phone Number: ${error.message}`);
        } else alert(`Too many tries. Please try login with Google or Facebook instead.`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  async function requestServerOTP(finalNumber) {
    const resp = await getOTPBySMS(finalNumber);
    if (resp['code'] === '200') {
      setTimeout(() => {
        setOpenOtp(true);
        setIsLoading(true);
        clearTimeout();
      }, 2000);
    } else {
      setIsLoading(false);
      alert(resp['message'] || 'Something Went Wrong. Please try again later.');
    }
  }

  async function onPhoneNumberSubmit() {
    const finalNumber = getPhoneNumberWithCountryCode();

    if (!finalNumber) {
      alert('Empty Phone number');
      return;
    } else if (!isValidPhoneNumber(finalNumber)) {
      alert('Invalid Phone Number');
      return;
    }

    setIsLoading(true);

    if (shouldRequestServerOTP(finalNumber)) {
      await requestServerOTP(finalNumber);
    } else {
      requestFirebaseOTP(finalNumber);
    }
  }

  function loginUsingServerOTP(_phoneNumber, otpCode) {
    loginWithSmsOTP(_phoneNumber, otpCode).then(resp => {
      if (resp.message === 'Invalid OTP' || resp.code === 500) {
        alert(resp.message);
        return;
      }

      setOpenOtp(false);
      returnLoginResult(resp);
    });
  }

  function loginUsingFirebaseToken(otpCode) {
    async function onOtpResult(result) {
      const { phoneNumber } = result.user;
      const token = await result.user.getIdToken();
      firebasePhoneLogin(phoneNumber, token).then(resp => {
        setOpenOtp(false);
        returnLoginResult(resp);
      });
    }

    window.confirmationResult
      .confirm(otpCode)
      .then(onOtpResult)
      .catch(e => {
        if (e.code) {
          switch (e.code) {
            case 'auth/invalid-verification-code':
              alert('Invalid OTP Code');
              break;

            default:
              alert('Sign-In Error \n' + e.code);
          }
        } else {
          alert('Sign-In Error, Please try again');
        }
      });
  }

  async function onOtpSubmit(otpCode) {
    if (!otpCode) {
      alert('Please enter otp code');
      return;
    }

    const _phoneNumber = getPhoneNumberWithCountryCode();

    if (shouldRequestServerOTP(_phoneNumber)) {
      loginUsingServerOTP(_phoneNumber, otpCode);
    } else {
      loginUsingFirebaseToken(otpCode);
    }
  }

  function onResentOtpClick() {
    setOpenOtp(false);
    onPhoneNumberSubmit();
  }

  function loginWithProvider(loginProvider) {
    return () => {
      return login(loginProvider).then(returnLoginResult);
    };
  }

  function loginGuest() {
    return loginWithGuest().then(returnLoginResult);
  }

  function returnLoginResult(resp) {
    props.close();
    props.loginOnClick(resp);
  }

  function renderPhoneLogin() {
    return (
      <div>
        <div className='text-aliments text-4xl font-bold pb-10'>Sign In</div>
        <div className='my-4 items-center'>
          <LoginTextInput
            placeholder='Phone number'
            value={phoneNumber}
            onChange={onPhoneNumberChange}
            type='text'
          />
        </div>
        <Button
          text={'Continue'}
          className='rounded-full uppercase'
          style={{
            minWidth: '160px',
          }}
          onClick={onPhoneNumberSubmit}
        />
      </div>
    );
  }

  function renderGoogleButton() {
    const loginProvider = new window.firebase.auth.GoogleAuthProvider();
    loginProvider.addScope('profile');
    loginProvider.addScope('email');

    const onClick = loginWithProvider(loginProvider);
    return (
      <div
        className='btn hover:shadow-lg shadow transition duration-500 ease-in-out'
        onClick={onClick}
      >
        <img src='/image/icon-google.png' alt='google icon' />
      </div>
    );
  }

  function renderFacebookButton() {
    const provider = new window.firebase.auth.FacebookAuthProvider();

    const onClick = loginWithProvider(provider);
    return (
      <div
        className='btn hover:shadow-lg shadow transition duration-500 ease-in-out'
        onClick={onClick}
      >
        <FontAwesomeIcon icon={['fab', 'facebook-f']} />
      </div>
    );
  }

  function renderOrderAsGuestButton() {
    return (
      <div onClick={() => loginGuest()}>
        <b>
          <u>Order as Guest</u>
        </b>
      </div>
    );
  }

  function getPhoneNumberWithCountryCode() {
    if (phoneNumber.startsWith('+')) return phoneNumber;
    else if (phoneNumber.startsWith('601')) return '+' + phoneNumber;
    else if (phoneNumber.startsWith('01')) return '+6' + phoneNumber;
    else if (phoneNumber.startsWith('1')) return '+60' + phoneNumber;
    else return undefined;
  }

  return (
    <div>
      {isLoading && <Loading />}
      <button id='sign-in-button' className='hidden' />
      <div className='login-dialog pt-24 pb-8 m-auto'>
        {renderPhoneLogin()}
        <div className='pb-6 pt-16'>Or login with</div>
        <div
          className={`flex flex-row justify-center ${
            props.isDining && !props.qrCompulsoryLogin ? 'pb-8' : 'pb-16'
          }`}
        >
          {renderFacebookButton()}
          {renderGoogleButton()}
        </div>
        <div
          className={`${
            props.isDining && !props.qrCompulsoryLogin ? 'pb-6' : 'hidden'
          }`}
        >
          {renderOrderAsGuestButton()}
        </div>

        <p className='text-sm mx-4 text-gray-500'>
          By continuing, you agree to our T&Cs* and{' '}
          <a
            target='_blank'
            className='text-gray-500'
            href='https://docs.google.com/document/d/e/2PACX-1vTvbWFdaobxK9c7vU3T_nwrcoIlhRp3tLv1vZjYE1jjyRxgQ85w2ZICGbxhtgw7E0qA3oLARMPJKzAItmTqT74/pub'
          >
            Privacy Policy
          </a>
          , and your data may be shared with our affliated companies in the Grab
          group for data analytics to improve service offerings and personalize
          your experience on Hubbo and Grab. For more information, please refer
          to <br />
          <a
            target='_blank'
            className='text-gray-500'
            href='https://www.grab.com/my/terms-policies/privacy-notice/'
          >
            Grab's Privacy Notice
          </a>
          .
        </p>
      </div>

      <ModalComponent
        openWhen={openOtp}
        close={() => {
          setIsLoading(false);
          setOpenOtp(false);
        }}
      >
        {openOtp && (
          <OtpDialog
            onOtpSubmit={onOtpSubmit}
            onResentOtpClick={onResentOtpClick}
            phoneNumber={getPhoneNumberWithCountryCode()}
          />
        )}
      </ModalComponent>
    </div>
  );
};

LoginDialog.propTypes = {
  close: PropTypes.func,
  loginOnClick: PropTypes.func,
  isDining: PropTypes.bool,
  qrCompulsoryLogin: PropTypes.bool,
  selectGuest: PropTypes.func,
};

export default LoginDialog;
