import ApiService from '../services/api';
import TYPES from './actionTypes';

export function getUserAccount() {
  return async dispatch => {
    const resp = await ApiService.get('/api/customer/account', true);
    dispatch({ type: TYPES.GET_USER_ACCOUNT, data: resp });

    return Promise.resolve(resp);
  };
}

export function closeLoginDialog() {
  return async dispatch => {
    dispatch({ type: TYPES.TOGGLE_LOGIN_DIALOG, data: false });
  };
}
