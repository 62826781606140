export default class TYPES {
  // Checkout
  static CHANGE_QUANTITY = 'CHANGE_QUANTITY';
  static CHECK_BOX_CLICK = 'CHECK_BOX_CLICK';
  static CLEAR_ALL = 'CLEAR_ALL';
  static RESTORE_CART = 'RESTORE_CART';
  static UPDATE_SESSION_STORAGE = 'UPDATE_SESSION_STORAGE';
  static RESET_CHECKOUT_COUNT = 'RESET_CHECKOUT_COUNT';
  static SAVE_CHECKOUT_COUNT = 'SAVE_CHECKOUT_COUNT';
  // END Checkout

  //Outlet
  static SAVE_MERCHANT_LIST = 'SAVE_MERCHANT_LIST';
  static SAVE_OUTLET_SCROLL_POSITION = 'SAVE_OUTLET_SCROLL_POSITION';
  static SAVE_LAST_SEARCH_TEXT = 'SAVE_LAST_SEARCH_TEXT';
  //END Outlet

  //Settings
  static GET_SETTINGS = 'GET_SETTINGS';
  static GET_SETTINGS_FAIL = 'GET_SETTINGS_FAIL';
  //

  // Menu
  static ADD_TO_CART = 'ADD_TO_CART';
  static UPDATE_CART = 'UPDATE_CART';
  static FILTER_MENU = 'FILTER_MENU';
  static GET_MENU_SUCCESS = 'GET_MENU_SUCCESS';
  static GET_MENU_FAIL = 'GET_MENU_FAIL';
  static GET_BRAIN_TREE_TOKEN_SUCCESS = 'GET_BRAIN_TREE_TOKEN_SUCCESS';
  static SAVE_SCROLL_POSITION = 'SAVE_SCROLL_POSITION';
  static TOGGLE_IS_SHOW_PROMOTION = 'TOGGLE_IS_SHOW_PROMOTION';
  // END Menu

  static SAVE_PARSED_QUERY = 'SAVE_PARSED_QUERY';
  static SAVE_API_KEY = 'SAVE_API_KEY';

  // auth
  static GET_USER_ACCOUNT = 'GET_USER_ACCOUNT';
  static TOGGLE_LOGIN_DIALOG = 'TOGGLE_LOGIN_DIALOG';

  // ticket
  static GET_TICKET_SUCCESS = 'GET_TICKET_SUCCESS';
  static GET_TICKET_FAIL = 'GET_TICKET_FAIL';
  static SET_TICKET_ID = 'SET_TICKET_ID';

  static UPDATE_TICKET = 'UPDATE_TICKET';
  static UPDATE_TICKET_SUCCESS = 'UPDATE_TICKET_SUCCESS';
  static UPDATE_TICKET_FAIL = 'UPDATE_TICKET_FAIL';

  // Delivery
  static GET_ADDRESS = 'GET_ADDRESS';
  static GET_ADDRESS_SUCCESS = 'GET_ADDRESS_SUCCESS';
  static GET_ADDRESS_FAIL = 'GET_ADDRESS_FAIL';

  static ADD_ADDRESS = 'ADD_ADDRESS';
  static ADD_ADDRESS_SUCCESS = 'ADD_ADDRESS_SUCCESS';
  static ADD_ADDRESS_FAIL = 'ADD_ADDRESS_FAIL';

  static DELETE_ADDRESS = 'DELETE_ADDRESS';
  static DELETE_ADDRESS_SUCCESS = 'DELETE_ADDRESS_SUCCESS';
  static DELETE_ADDRESS_FAIL = 'DELETE_ADDRESS_FAIL';

  static SELECT_ADDRESS = 'SELECT_ADDRESS';
  static GET_QUOTATION = 'GET_QUOTATION';
  static GET_QUOTATION_SUCCESS = 'GET_QUOTATION_SUCCESS';
  static GET_QUOTATION_FAIL = 'GET_QUOTATION_FAIL';
  static SELECT_FIRST_ADDRESS = 'SELECT_FIRST_ADDRESS';
  static REMOVE_SELECTED_ADDRESS = 'REMOVE_SELECTED_ADDRESS';
}
