import React, { Component, Fragment } from 'react';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';
import './App.scss';
import AboutPage from './page/about/about';
import AddressFormPage from './page/addNewAddress/addressFormPage';
import AddressListPage from './page/addressList/addressListPage';
import AddressSelectionPage from './page/addressSelection/addressSelectionPage';
import BoostVerify from './page/boostVerify/boostVerify';
import OutletPage from './page/outlet/outletPage';
import CheckoutPage from './page/checkout/checkout';
import DynamicCheckoutPage from './page/dynamicCheckout/dynamicCheckoutPage';
import DynamicLandingPage from './page/dynamicLanding/dynamicLandingPage';
import DynamicMenuPage from './page/dynamicMenu/dynamicMenuPage';
import DynamicOrderPage from './page/dynamicOrder/dynamicOrderPage';
import DynamicThankYouPage from './page/dynamicThankyou/dynamicThankyou';
import GlobalPage from './page/global/global';
import HistoryPage from './page/history/history';
import LandingPage from './page/landing/landingPage';
import MenuPage from './page/menu/menu';
import NearbyMerchantPage from './page/nearbyMerchant/nearbyMerchant';
import StartUpPage from './page/startup/startup';
import ThankYouPage from './page/thankyou/thankYou';
import WechatVerify from './page/wechatVerify/wechatVerify';
import CashbackLandingPage from './page/cashbackLanding/cashbackLandingPage';
import CashbackHistoryPage from './page/cashbackHistory/cashbackHistory';

export const outletRoute = '/outlet';
export const menuRoute = '/menu';
export const landingRoute = '/landing';
export const addressListRoute = '/addressList';
export const addressSelectionRoute = '/addressSelection';
export const addressFormRoute = '/addressForm';
export const checkoutRoute = '/checkout';
export const thankyouRoute = '/thankyou';
export const aboutRoute = '/about';
export const startupRoute = '/startup';
export const historyRoute = '/history';
export const nearbyRoute = '/nearby';
export const cashbackHistoryRoute = '/cashback-history';
export const wechatVerifyRoute = '/wechatVerify';
export const boostVerifyRoute = '/boostVerify';
export const cashbackClaimRoute = '/cashback/claim';
export const dynamicLandingRoute = '/dynamic/landing';
export const dynamicMenuRoute = '/dynamic/menu';
export const dynamicCheckoutRoute = '/dynamic/checkout';
export const dynamicOrderRoute = '/dynamic/order';
export const dynamicThankyouRoute = '/dynamic/thankyou';

export default class Routes extends Component {
  _isWechat() {
    return !!window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i);
  }

  content = withRouter(props => {
    const hideGlobalPage = [
      outletRoute,
      landingRoute,
      addressListRoute,
      addressSelectionRoute,
      addressFormRoute,
      checkoutRoute,
      nearbyRoute,
      cashbackClaimRoute,
      dynamicLandingRoute,
      dynamicMenuRoute,
      dynamicCheckoutRoute,
      dynamicOrderRoute,
      dynamicThankyouRoute,
    ].includes(props.location.pathname);
    return (
      <div
        className={`main ${this._isWechat() &&
          !props.location.pathname.includes('/dynamic') &&
          'items-center'}`}
      >
        {this._isWechat() && !props.location.pathname.includes('/dynamic') ? (
          <div>
            <div style={{ position: 'fixed', right: '50px', top: '30px' }}>
              <img
                src='/image/arrow_to_right.png'
                width='40px'
                alt='triple-dot'
              />
            </div>
            <ol className='pt-40'>
              <li style={{ paddingBottom: '10px' }}>
                点击右上角{' '}
                <img
                  src='/image/triple-dot.png'
                  width='40px'
                  alt='triple-dot'
                />
              </li>
              <li className='self-center'>
                在{' '}
                <img
                  src='/image/outline_public_black.png'
                  width='40px'
                  alt='triple-dot'
                />{' '}
                浏览器中打开
              </li>
            </ol>
          </div>
        ) : (
          <React.Fragment>
            {!hideGlobalPage && <Route path='/' component={GlobalPage} />}
            <Switch>
              <Route path={outletRoute} component={OutletPage} />
              <Route path={menuRoute} component={MenuPage} />
              <Route path={landingRoute} component={LandingPage} />
              <Route path={addressListRoute} component={AddressListPage} />
              <Route
                path={addressSelectionRoute}
                component={AddressSelectionPage}
              />
              <Route path={addressFormRoute} component={AddressFormPage} />
              <Route path={checkoutRoute} component={CheckoutPage} />
              <Route path={thankyouRoute} component={ThankYouPage} />
              <Route path={aboutRoute} component={AboutPage} />
              <Route path={startupRoute} component={StartUpPage} />
              <Route path={historyRoute} component={HistoryPage} />
              <Route path={cashbackHistoryRoute} component={CashbackHistoryPage} />
              <Route path={wechatVerifyRoute} component={WechatVerify} />
              <Route path={boostVerifyRoute} component={BoostVerify} />
              <Route path={nearbyRoute} component={NearbyMerchantPage} />
              <Route path={cashbackClaimRoute} component={CashbackLandingPage} />
              <Route path={dynamicLandingRoute} component={DynamicLandingPage} />
              <Route path={dynamicMenuRoute} component={DynamicMenuPage} />
              <Route path={dynamicCheckoutRoute} component={DynamicCheckoutPage} />
              <Route path={dynamicOrderRoute} component={DynamicOrderPage} />
              <Route path={dynamicThankyouRoute} component={DynamicThankYouPage} />
              <Redirect to={menuRoute} />
            </Switch>
          </React.Fragment>
        )}
      </div>
    );
  });

  render() {
    return (
      <BrowserRouter>
        <this.content />
      </BrowserRouter>
    );
  }
}
