import { parsePrice } from './utils';

export function getApiErrorMessage(key) {
  const message = {
    'api.invalid.merchant': 'Unexpected error',
    'api.invalid.coupon': 'Please enter valid coupon code',
    'api.invalid.coupon.merchant.id': 'Not applicable for current merchant',
    'api.invalid.coupon.fully.redeem': 'Coupon not available',
    'api.invalid.coupon.min.purchase.30.0':
      'Only applicable for RM30.00 and above',
    'api.invalid.new.customer.only': 'New customer only',
    'api.error.delivery.only': 'Coupon apply for delivery only',
    'api.payment.failed':
      'Payment declined by bank. Please try with another card.',
    'api.key.invalid': 'Login is required',
  };

  if (key.includes('api.invalid.coupon.min.purchase')) {
    let total = key.replace('api.invalid.coupon.min.purchase.', '');
    return `Only applicable for RM${parsePrice(total)} and above`;
  } else if (key.includes('api.invalid.max.per.customer')) {
    let total = key.replace('api.invalid.max.per.customer.', '');
    return `Maximum ${total} redeem per customer`;
  }

  return message[key] || key;
}

export function paymentMethodList() {
  return {
    TWOC2P_GRABPAY: {
      name: 'GrabPay',
      img: './image/grab_pay_square_logo.png',
    },
    TWOC2P_CC: {
      name: 'Credit or Debit Card (2C2P)',
      img: './image/icon_credit_card.png',
    },
    LINKS: {
      name: 'Credit or Debit Card (L)',
      img: './image/icon_credit_card.png',
    },
    TWOC2P_ALIPAY: {
      name: 'Alipay',
      img: './image/alipay_2c2p.png',
    },
    TWOC2P_WECHAT: {
      name: 'WeChat Pay',
      img: './image/wechat.png',
    },
    TWOC2P_SHOPEEPAY: {
      name: 'ShopeePay',
      img: './image/shopee_pay_icon.jpeg',
    },
    TWOC2P_TNG: {
      name: 'Touch ‘n Go eWallet',
      img: './image/touchngo_logo.png',
    },
    TWOC2P_FPX: {
      name: 'Online Banking (FPX)',
      img: './image/fpx_icon.png',
    },
    FINEXUS: {
      name: 'Credit or Debit Card (F)',
      img: './image/icon_credit_card.png',
    },
    BRAINTREE: {
      name: 'Credit or Debit Card',
      img: './image/icon_credit_card.png',
    },
    IPAY88: { name: 'ipay88', img: './image/icon_visa.png' },
    VCASH: { name: 'vcash', img: './image/icon_vcash.png' },
    BOOST: { name: 'Boost', img: './image/boost_logo.png' },
    ALIMENTS_POINT: {
      name: 'HUBBO POS Point',
      img: './image/hubbo_logo.png',
    },
    PAY_AT_COUNTER: {
      name: 'Pay at counter',
      img: './image/icon_pay_at_counter.png',
    },
    REDEMPTION: { name: 'Redemption', img: './image/icon_visa.png' },
    WECHATPAY: { name: 'WECHATPAY', img: './image/wechat pay_logo.png' },
    IPAY88_GRABPAY: {
      name: 'GrabPay',
      img: './image/grab_pay_square_logo.png',
    },
    IPAY88_TNG: {
      name: 'Touch ‘n Go eWallet',
      img: './image/touchngo_logo.png',
    },
    IPAY88_SHOPEEPAY: {
      name: 'ShopeePay',
      img: './image/shopee_pay_icon.jpeg',
    },
  };
}

export const getPaymentMethodSortingArray = () => [
  'BRAINTREE',
  'TWOC2P_CC',
  'LINKS',
  'FINEXUS',
  'IPAY88',
  'TWOC2P_SHOPEEPAY',
  'IPAY88_SHOPEEPAY',
  'VCASH',
  'BOOST',
  'TWOC2P_ALIPAY',
  'TWOC2P_WECHAT',
  'WECHATPAY',
  'TWOC2P_GRABPAY',
  'IPAY88_GRABPAY',
  'TWOC2P_TNG',
  'IPAY88_TNG',
  'TWOC2P_FPX',
  'ALIMENTS_POINT',
  'PAY_AT_COUNTER',
  'REDEMPTION',
];

export const CONSTANTS = {
  PARTNER: {
    WECHAT_PAY: 'WeChat Pay',
    TOUCH_AND_GO: 'Touch and Go',
    GRAB_PAY: 'Grab Pay',
    IPAY88_GRABPAY: 'Ipay88_GrabPay',
    IPAY88_TNG: 'Ipay88_Tng',
    IPAY88_SHOPEEPAY: 'Ipay88_ShopeePay',
    BOOST: 'Boost',
    FINEXUS: 'Finexus',
    TWOC2P_GRABPAY: 'Twoc2p_GrabPay',
    TWOC2P_FPX: 'TWOC2P_FPX',
    TWOC2P_CC: 'TWOC2P_CC',
    TWOC2P_ALIPAY: 'TWOC2P_ALIPAY',
    TWOC2P_WECHAT: 'TWOC2P_WECHAT',
    TWOC2P_SHOPEEPAY: 'TWOC2P_SHOPEEPAY',
    TWOC2P_TNG: 'Twoc2p_Tng',
    LINKS: 'LINKS',
  },
  DINE_IN: 'DINE_IN',
  TAKE_AWAY: 'TAKE_AWAY',
};

export const API = {
  SUCCESS: '200',
};

export class MenuSession {
  static merchantId = 'merchantId';
  static dinningMethod = 'diningMethod';
  static savedParsedQuery = 'savedParsedQuery';
}

export class OutletSession {
  static chain = 'chain';
  static tableNo = 'tableNo';
}

export class PreOrderSession {
  static preOrderSlot = 'preOrderSlot';
}
