import { combineReducers } from 'redux';

import Checkout from './checkout';
import Delivery from './delivery';
import Menu from './menu';
import Global from './global';
import Auth from './auth';
import Ticket from './ticket';
import Outlet from './outlet';
import Settings from "./setting";

const appReducer = combineReducers({
  Checkout,
  Menu,
  Global,
  Auth,
  Ticket,
  Delivery,
  Outlet,
  Settings
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
